import React from 'react';
import AppStore from '../assets/app-store.svg';
import PlayStore from '../assets/play-store.svg';
import Logo from '../assets/swapp-logo.svg';

import Layout from '../components/layout';
import indexStyles from './index.module.css';

const IndexPage: React.SFC = () => (
  <Layout>
    <div className={indexStyles.logo}>
      <Logo />
    </div>
    <div className={indexStyles.badges}>
      <div className={indexStyles.badge}>
        <a
          href="https://play.google.com/store/apps/details?id=su.swapbox"
          target="_blank"
        >
          <PlayStore height="100%" width="100%" />
        </a>
      </div>
      <div className={indexStyles.badge}>
        <a href="https://itunes.apple.com/app/id1438210008" target="_blank">
          <AppStore height="100%" width="100%" />
        </a>
      </div>
    </div>
  </Layout>
);

export default IndexPage;
